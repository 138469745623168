<template>
  <div class="tfoms-info2">
    <div class="tfoms-info2__header d-flex">
      <div
        class="tfoms-info2__header-title tfoms-info2__block"
        :class="color + '--text'"
      >
        {{ title }}
      </div>
      <div v-if="false" class="tfoms-info2__header-status tfoms-info2__block">
        <div :class="color + '--text'">{{ diff > 0 ? "+" : "" }}{{ diff }}</div>
        <div>{{ labelRub }}</div>
        <div>за {{ date }}</div>
      </div>
    </div>

    <div class="tfoms-info2__content d-flex">
      <div class="tfoms-info2__block">
        <div class="tfoms-info2__content-value">
          {{ value | numberSplit }}
        </div>
        <div class="tfoms-info2__header-status">{{ labelUnit }}</div>
      </div>
      <div class="tfoms-info2__block">
        <div class="tfoms-info2__content-value">
          <template v-if="value2">
            {{ value2 | numberSplit }}
          </template>
          <template v-else> 0 </template>
        </div>
        <div class="tfoms-info2__header-status">{{ labelRub }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "view-tfoms-info22",
  props: {
    title: {
      type: String,
      default: "ПРИКРЕПЛЕННОЕ НАСЕЛЕНИЕ",
    },
    subTitle: {
      type: String,
      default: "Человек",
    },
    value: {
      type: Number,
      default: 98235,
    },
    value2: {
      type: Number,
      default: 0,
    },
    diff: {
      type: Number,
      default: 134,
    },
    date: {
      type: String,
      default: "октябрь",
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    labelRub: {
      type: String,
      default: "",
    },
    labelUnit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.tfoms-info2 {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  height: 144px;

  &__block {
    width: 120px;
  }
  &__header {
    &-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 12px;

      color: var(--v-blue-grey-lighten3);
    }
    &-status {
      color: var(--v-blue-grey-lighten3);
      letter-spacing: -0.12px;
      font-size: 12px;
      line-height: 90%;
    }
  }

  &__content {
    &-value {
      font-size: 26px;
      color: var(--v-blue-grey-lighten3);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-diff {
      font-size: 16px;

      font-weight: 600;
    }
    &-date {
      font-size: 12px;
      color: var(--v-blue-grey-lighten3);
      font-weight: 600;
    }
    &-meta {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
